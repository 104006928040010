import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import Layout from '~components/layouts/Default';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Haftungsausschluss: Haftung für Inhalte, Links etc." desc="Der Haftungsausschluss enthält wichtige Informationen zu Haftungen und Haftungsbeschränkungen.">
      <div className="bg-gray-50 py-8">
        <PageHeader
          title="Haftungsausschluss"
          breadcrumb={[
            {
              title: 'Haftungsausschluss',
              link: '/haftungsausschluss/',
              current: true
            }
          ]}
        />
        <Container>
          <div className="prose prose-lg mx-auto mt-6">{parse(data.rechtliches.text)}</div>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    rechtliches(title: { eq: "Haftungsausschluss" }) {
      text
    }
  }
`;
